import { memo } from "react";
import BreadcrumbItem from "app/pages/Breadcrumb/BreadcrumbItem";
import { FormattedMessage } from "react-intl";
import Breadcrumb from "app/pages/Breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
import { checkStepFromCurrentPath, checkStepStatus } from "app/pages/Breadcrumb/BreadcrumbUtils";
import "./SmartDPBreadcrumb.scss";

const STEPS = [
	{ value: 1, paths: ["/booking/quote"] },
	{ value: 2, paths: ["/booking/payment"] },
	{ value: 3, paths: ["/booking/confirmation"] },
];

const SmartDPBreadcrumb = () => {
	const location = useLocation();
	const currentStep = checkStepFromCurrentPath(STEPS, location?.pathname);
	return (
		<div className="sdp-breadcrumb">
			<Breadcrumb>
				{STEPS.map(step => (
					<BreadcrumbItem
						key={step.value}
						status={checkStepStatus(step.value, currentStep)}
						label={
							<FormattedMessage id={`sdp.booking.breadcrumb.step.${step.value}`} />
						}
					/>
				))}
			</Breadcrumb>
		</div>
	);
};

export default memo(SmartDPBreadcrumb);
