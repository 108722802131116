/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import { TUNNEL_STEP_STATUS } from "app/constants";

export const checkStepFromCurrentPath = (steps, currentPath) => {
	let step = 1;
	if (steps && steps.length > 0) {
		steps.map(({ value, paths }) => {
			if (paths.some(path => currentPath.includes(path))) {
				step = value;
			}
		});
	}
	return step;
};

export const checkStepStatus = (step, currentStep) =>
	currentStep > step
		? TUNNEL_STEP_STATUS.PASSED
		: currentStep === step
		? TUNNEL_STEP_STATUS.ACTIVE
		: TUNNEL_STEP_STATUS.DISABLED;
