import PropTypes from "prop-types";
import IconBreadcrumCheck from "app/pages/.shared/static/icons/IconBreadcrumCheck";
import { values } from "lodash";
import { TUNNEL_STEP_STATUS } from "app/constants";
import "./breadcrumb.scss";

const breadcrumbsStatusClassnames = {
	[TUNNEL_STEP_STATUS.ACTIVE]: "breadcrumbs-item--active",
	[TUNNEL_STEP_STATUS.DISABLED]: "breadcrumbs-item--disabled",
	[TUNNEL_STEP_STATUS.PASSED]: "breadcrumbs-item--passed",
};

const BreadcrumbItem = ({ label, status, position }) => (
	<>
		<li
			className={`breadcrumbs-item ${breadcrumbsStatusClassnames[status] || ""}`}
			data-testid="breadcrumbs-item"
		>
			<div className="breadcrumbs-item__position">
				{status === TUNNEL_STEP_STATUS.PASSED ? (
					<IconBreadcrumCheck height={8} width={16} />
				) : (
					position
				)}
			</div>
			<span className="breadcrumbs-item__label breadcrumbs-item__label">{label}</span>
		</li>
		<div className="breadcrumbs-item__spacer" />
	</>
);

BreadcrumbItem.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	status: PropTypes.oneOf(values(TUNNEL_STEP_STATUS)),
	position: PropTypes.number,
};

export default BreadcrumbItem;
